//@import "syntax-highlighting";

.c-aa0d91 { color: #aa0d91; }
.c-5c2699 { color: #5c2699; }
.c-007400 { color: #007400; }
.c-0e0eff { color: #0e0eff; }
.c-c41a16 { color: #c41a16; }
.c-2e0d6e { color: #2e0d6e; }
.c-1c00cf { color: #1c00cf; }
.c-26474b { color: #26474b; }
.c-3f6e74 { color: #3f6e74; }
.c-023d10 { color: #023d10; }
.c-643820 { color: #643820; }

$main-column-width: 750px;
$main-column-hpadding: 1.5em;
$main-column-smallish-hpadding: 1em;

@mixin display-flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
}

@mixin justify-content($mode) {
    -webkit-justify-content: $mode;
    -moz-justify-content: $mode;
    -ms-flex-pack: $mode;
    justify-content: $mode;
}

@mixin flex-wrap($mode) {
    -webkit-flex-wrap: $mode;
    -moz-flex-wrap: $mode;
    -ms-flex-wrap: $mode;
    flex-wrap: $mode;
}

@mixin flex-direction($mode) {
    -webkit-flex-direction: $mode;
    -moz-flex-direction: $mode;
    -ms-flex-direction: $mode;
    flex-direction: $mode;
}

@mixin align-items($mode) {
    -webkit-align-items: $mode;
    -moz-align-items: $mode;
    -ms-align-items: $mode;
    align-items: $mode;
}

@mixin flex-grow($value) {
    -webkit-flex-grow: $value;
    -moz-flex-grow: $value;
    -ms-flex-grow: $value;
    flex-grow: $value;
}

@mixin flex-shrink($value) {
    -webkit-flex-shrink: $value;
    -moz-flex-shrink: $value;
    -ms-flex-shrink: $value;
    flex-shrink: $value;
}

@mixin regular-width {
    @media (min-width: 600px) {
        @content;
    }
}
@mixin smallish-width {
    @media (max-width: 600px) {
        @content;
    }
}
@mixin smallest-width {
    @media (max-width: 350px) {
        @content;
    }
}

@mixin user-select($mode) {
    -webkit-user-select: $mode;
    user-select: $mode;
}

// fix for IE
main {
    display: block;
}

* {
    margin: 0;
    padding: 0;
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    // note: adding the canvas breaks subpixel rendering in safari :(
//    -webkit-font-smoothing: antialiased;
//    -moz-osx-font-smoothing: grayscale;
}
html {
//    overflow-y: auto;
//    -webkit-overflow-scrolling: touch; // slower deceleration
}
html, body {
    height: 100%;
}
html, body {
//    overflow-y: scroll;
//    -webkit-overflow-scrolling: touch; // slower deceleration
}
.wf-loading {
//    opacity: 0;
}
body {
    font-family: "Source Sans Pro", sans-serif;
    color: #111;
    text-rendering: optimizeLegibility;
    -webkit-font-variant-ligatures: common-ligatures;
    font-variant-ligatures: common-ligatures;
//    font-size: 1.1em;
//    @include smallish-width {
//        font-size: 1em;
//    }
//    @include display-flex;
//    @include flex-direction(column);
//    @include justify-content(top);
//    @include align-items(stretch);
}

abbr {
    cursor: help;
    text-decoration: none;
}

// Sticky footer
.flex-column {
    @include display-flex;
    @include flex-direction(column);
    height: 100%;
    max-width: $main-column-width;
    margin: 0 auto;
    padding: 0 $main-column-hpadding;
    @include smallish-width {
        padding: 0 $main-column-smallish-hpadding;
    }
}
//footer {
//    position: absolute;
//    left: 0;
//    right: 0;
//}
.column-wrapper {
//    max-width: $main-column-width;
//    margin: 0 auto;
}
.noshrink {
    @include flex-shrink(0);
}
.grow {
    @include flex-grow(1);
}

body.index {
    #site-header {
        font-size: 1.5em;
        @include smallish-width {
            font-size: 1.2em;
        }
        @include smallest-width {
            font-size: 1em;
        }
    }
    main {
        @include display-flex;
        @include flex-direction(column);
        @include justify-content(center);
        @include align-items(center);
        height: 90%;
		text-align: center;
        
        // override default styles from other pages
        margin: 0 auto;
        padding: 0;
        width: 100%;
//    header {
//        text-align: center;
//        width: 500px;
//        margin: auto 1em;
//    }
    }
    #profiles {
        margin-top: 0.3em;
        li {
            height: 1em;
            width: 1em;
        }
    }
}
#site-header {
    @include smallish-width {
        font-size: 0.8em; // or 0.9em if body is already 0.9
    }
    text-align: center;
    margin: 0 auto;
    padding: 0.5em 0em;
    h1 {
        font-kerning: none;
        white-space: nowrap;
        font-weight: 400;
        font-size: 1.5em;
        position: relative; // allow absolute children
        display: inline-block; // shrink to fit contents but allow children offsetLeft to work properly
//        cursor: default; // no text selection cursor
        .letter {
            -webkit-tap-highlight-color: transparent;
            &.dropped {
                color: transparent;
            }
        }
    }    
    a {
        text-decoration: none;
        color: inherit;
        // v3-style transition
//        transition: -webkit-transform 0.3s;
//        display: block;
//        &:hover {
//            -webkit-transform: rotate(-2deg) scale(1.05);
//            -moz-transform: rotate(-2deg) scale(1.05);
//            transform: rotate(-2deg) scale(1.05);
//        }
    }
    h1 + * {
        position: relative;
        z-index: 2;
    }
    .fade {
        @include user-select(none);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 31px;
        margin: -31px;
        border: 1px;
        border-image: radial-gradient(rgba(255,255,255,0), white 60%) 49% 49% / 31px stretch;
        z-index: 0;
        pointer-events: none;
    }
    #header-effect {
        @include user-select(none);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -2;
    }
    canvas {
        image-rendering: -webkit-optimize-contrast;
        image-rendering: -moz-crisp-edges;
        image-rendering: pixelated;
        -ms-interpolation-mode: nearest-neighbor; // doesn't actually seem to work in Edge
    }
}

#profiles {
    list-style: none;
    @include display-flex;
    @include justify-content(center);
    @include flex-wrap(wrap);
    margin-top: 0.2em; // overridden for body.index
    li {
        display: inline-block; // not really necessary but helps some browsers
        position: relative;
        height: 1.2em;
        min-width: 1.2em;
//        display: inline;
        margin: 0em 0.4em;
        &:hover {
            $hover-hysteresis: 0.5em;
            a { padding: $hover-hysteresis; margin: -$hover-hysteresis; }
        }
        &:not(:hover) path {
            fill: #ddd;
        }
        
        // extra masking for header effect
        background-color: white;
        box-shadow: white 0px 0px 0.5em 0.5em;
        border-radius: 50%; // children aren't clipped, because of the default overflow:visible
//        border: 1px solid red;
    }
    a, svg {
//        background-color:rgba(127,0,0,0.5);
        position: absolute;
		color: transparent;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    svg path {
        transition: fill 0.2s;
    }
}

main {
//    max-width: $main-column-width;
    position: relative; // prevent text selection from spanning 100% width
    margin-top: 2em;
//    margin: 2em auto 0em;
//    padding-bottom: 2em;
//    @include smallish-width {
//        padding-left: $main-column-smallish-hpadding;
//        padding-right: $main-column-smallish-hpadding;
//    }
}

.main-header {
    margin-bottom: 2em;
}
//article, main {
//    @include regular-width {
//        font-size: 1.05em;
//    }
//}
article {
    position: relative;
//    font-weight: 300;
    header {
		a, a:hover, a:visited {
			text-decoration: none;
			color: inherit;
		}
    }
    h1 {
        font-weight: 700;
        font-size: 2.5em;
        line-height: 1em;
        letter-spacing: -0.02em;
        @include smallish-width { // even smaller than from parent adjustment
            font-size: 2em;
        }
    }
    .subhead {
        margin: 0;
        font: 200 italic 1.2em "Source Sans Pro", sans-serif;
		text-align: left; // override default p{justify}
    }
    .post-meta {
        font-weight: 300;
        font-size: 0.9em;
        margin: 0em 0em;
        color: #999;
    }
}
// these were previously under article
    h2 {
        margin: 1.5em 0 0.5em;
        font: 400 1.75em "Source Serif Pro", serif;
    }
	h3 {
		margin: 1em 0 0.5em;
        font: 700 1.3em "Source Sans Pro", serif;
	}
    p {
        text-align: justify;
        @include smallest-width {
            text-align: left;
        }
        margin: 1.2em 0;
        line-height: 1.4;
        aside &:first-child {margin-top: 0;}
        &:last-child {
            margin-bottom: 0;
        }
    }
    h2 + p, h3 + p {
        margin-top: -0.3em; // try to match spacing of <h2></h2><pre></pre>
    }

// hack to reset sizes for monospace fonts
pre, code, kbd, samp {
    font-family: monospace, monospace;
    font-size: 1em;
}

code, kbd, samp {
//    -webkit-
    font-family: Consolas, "Source Code Pro", Menlo, Monaco, monospace;
//    font-size: 12pt;
//    font-size-adjust: 0.5;
//    font-size: 0.9em;
//    letter-spacing: -0.05ex;
//    word-spacing: -0.2ex;
//    color: #333;
}

a {
    /* thanks wikipedia */
    color: #0645ad;
//    &:active { color: #faa700; }
    &:visited { color: #002070; }
    text-decoration: none;
    &:hover { text-decoration: underline; }
}

p code, li code, aside code, h1 code, h2 code, h3 code {
    font-size: 0.9em;
    white-space: nowrap;
    border-radius: 2px;
    background-color: #f8f8f8;
    padding: 0.1em 0.2em 0.05em;
    margin: -0.1em 0em;
}
code.breakable {
    white-space: normal !important;
}
h1 > code {
    padding: 0.1em 0.1em 0em;
    margin: -0.1em -0.1em 0em;
}

var {
	font-style: normal;
}
pre kbd var {
    font-style: italic;
    color: #666;
//    font-weight: bold;
    &::before {
        content: "\ab";
        color: #bbb;
    }
    &::after {
        content: "\bb";
        color: #bbb;
    }
}
strong {
    font-weight: 700;
}

pre {
    font-size: 0.8em;
    border-radius: 3px;
    background-color: #f8f8f8;
    padding: 0.4em 0.6em;
    margin: 0em -0.2em;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    // more tappable targets for scrolling
    @include smallish-width {
        padding-top: 1em;
        padding-bottom: 1em;
    }
}

pre code {
//    background-color: transparent;
//    color: #000;
//    font-size: 0.9em;
//    overflow-x: scroll;
}
pre a.select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: none;
    border: none;
    text-transform: uppercase;
    float: right;
//    text-align: right;
//    position: run-in;
//    display: block;
    cursor: pointer;
    background-color: #f8f8f8;
    font: 400 0.8em "Source Code Pro", monospace;
}

.MathJax_Preview {
    color: #888;
    text-align: center;
    margin: 1em 0;
}
.MathJax_Display {
	box-sizing: border-box; // avoid sizing issues from MathJax's width:100%
    padding: 0.5em 0em !important; // override defaults; left/right must be 0 to avoid issues from width:100%
    margin: 0em !important;
	-webkit-overflow-scrolling: touch;
	overflow-x: auto;
	overflow-y: hidden;
	span {
		-webkit-tap-highlight-color: transparent; // I guess mathjax has click handlers
	}
}
.MathJax_Display + p, script[type="math/tex; mode=display"] + p {
//    margin-top: 0.5em;
}

body.page .main-header h1 {
    letter-spacing: -0.02em;
    font: 400 2.5em "Source Serif Pro", serif;
//	color: #ddd;
//    text-align: center;
    @include smallish-width { // even smaller than from parent adjustment
        font-size: 2em;
    }
}
body.archive {
    main {
//        margin-top: 5em;
    }
    ol {
        list-style: none;
    }
    li {
        margin: 2em 0;
    }
    article {
        a {
            color: inherit;
            text-decoration: none;
        }
        font-size: 0.7em;
        h1 {
            font-weight: 400;
            &.external::after {
                content: "↗︎";
                font-weight: bold;
                font-size: 0.5em;
                position: relative;
                bottom: 8px;
                left: 4px;
            }
        }
        .subhead {
            font-size: 1.3em;
        }
        .post-meta {
            font-size: 1.2em;
        }
    }
}
.end-mark {
	text-align: center;
	margin: 3em 0 1em;
	svg {
		width: 2em;
		height: 2em;
	}
}
footer#main-footer {
//    border-top: 1px solid #eee;
//    background-color: #f7f7f7;
//    max-width: $main-column-width;
    margin: 2em 0 0em;
//    padding: 1em 0 3em;
    padding-bottom: 2em;
    .copyright {
        text-align: center;
        margin-top: 0.5em;
        padding-top: 1em;
        font-size: 0.7em;
        color: #ccc;
    }
    a, a:visited {
        color: inherit;
    }
}
nav {
    a, a:visited { // duplicated because index.html doesn't use <footer>
        color: inherit;
    }
    color: #889;
}
nav.related-posts {
//    margin: 3em 0 0em;
    @include display-flex;
    @include justify-content(space-between);
    padding: 0.5em 0 0.3em;
    border-bottom: 1px solid #f7f7f7;
    a {
        margin: 0em 0.8em; // note: not collapsed, due to flexbox
    }
    a:first-child {
        margin-left: 0;
    }
    a:last-child {
        margin-right: 0;
    }
    a[rel="previous"]::before {
        content: "\ab\a0";
    }
    a[rel="next"]::after {
        content: "\a0\bb";
    }
    text-align: center;
    font-size: 0.8em;
}

body.index nav {
    font-size: 1em;
    margin: 3em 0 0;
    border: none;
}

aside {
//	padding: 0 3em;
    margin: 1em 0;
	color: #999;
//	font-style: italic;
	p { line-height: 1.2; }
    a { color: #333; }
    a:visited { color: #555; }
    
    &.meta {
        font-size: 0.8em;
        padding: 1em;
        border: 1px solid #eee;
        background-color: #fdfdfd;
        a { color: #444; }
        a:visited { color: #777; }
    }
    &.note {
    	font-size: 0.9em;
    	color: #888;
//        &::before {
//            content: "Note: ";
//            text-transform: uppercase;
//            font-size: 0.8em;
//            margin-bottom: 0.5em;
//            font-weight: bold;
//            display: block;
//        }
        background-color: #fffff6;
        border: 1px solid #eee;
        padding: 1em;
        .intro {
            color: #777;
            font-weight: bold;
        }
    }
}

.file-download {
    background-color: #fafafa;
    border: 1px solid #ddd;
    border-radius: 0.2em;
    padding: 1em;
    margin: 1.2em auto;
    display: table;
    &:hover {
        background-color: #f5f5f5;
        border-color: #ccc;
        text-decoration: none;
    }
    img, h1 {
        display: table-cell;
        vertical-align: middle;
    }
    img {
        padding-right: 0.5em;
    }
    h1 {
        font-size: 1em;
        font-weight: normal;
        letter-spacing: normal;
    }
}

a.twitter {
    border-radius: 4px;
    padding: 1px 10px 1px 9px;
    display: inline-block;
    height: 28px;
//    line-height: 26px;
//    font: normal normal normal 11px/18px 'Helvetica Neue',Arial,sans-serif;
//    font-size: 13px;
//    background-color: #1b95e0;
//    color: #fff;
//    text-decoration: none;
    &:hover { background-color: #0c7abf };
    &.retweet {
        padding-left: 10px;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3NSA3MiI+PHN0eWxlPnBhdGh7ZmlsbDojQUFCOEMyO306aG92ZXIgcGF0aHtmaWxsOiMxOUNGODY7fTwvc3R5bGU+PHBhdGggZD0iTTcwLjY3NiAzNi42NDRDNzAuMTY2IDM1LjYzNiA2OS4xMyAzNSA2OCAzNWgtN1YxOWMwLTIuMjEtMS43OS00LTQtNEgzNGMtMi4yMSAwLTQgMS43OS00IDRzMS43OSA0IDQgNGgxOGMuNTUyIDAgLjk5OC40NDYgMSAuOTk4VjM1aC03Yy0xLjEzIDAtMi4xNjUuNjM2LTIuNjc2IDEuNjQ0LS41MSAxLjAxLS40MTIgMi4yMi4yNTcgMy4xM2wxMSAxNUM1NS4xNDggNTUuNTQ1IDU2LjA0NiA1NiA1NyA1NnMxLjg1NS0uNDU1IDIuNDItMS4yMjZsMTEtMTVjLjY2OC0uOTEyLjc2Ny0yLjEyMi4yNTYtMy4xM3pNNDAgNDhIMjJjLS41NCAwLS45Ny0uNDI3LS45OTItLjk2TDIxIDM2aDdjMS4xMyAwIDIuMTY2LS42MzYgMi42NzctMS42NDQuNTEtMS4wMS40MTItMi4yMi0uMjU3LTMuMTNsLTExLTE1QzE4Ljg1NCAxNS40NTUgMTcuOTU2IDE1IDE3IDE1cy0xLjg1NC40NTUtMi40MiAxLjIyNmwtMTEgMTVjLS42NjcuOTEyLS43NjcgMi4xMjItLjI1NSAzLjEzQzMuODM1IDM1LjM2NSA0Ljg3IDM2IDYgMzZoN2wuMDEyIDE2LjAwM2MuMDAyIDIuMjA4IDEuNzkyIDMuOTk3IDQgMy45OTdoMjIuOTljMi4yMDggMCA0LTEuNzkgNC00cy0xLjc5Mi00LTQtNHoiLz48L3N2Zz4=);
    }
}

aside.share {
    position: absolute;
    left: 100%;
    margin: 0 30px;
}

figure {
//    display: inline-block;
    &.fullwidth svg, &.fullwidth img {
        display: block;
        margin: 0 auto;
        max-width: 100%;
        height: auto;
    }
    &.right {
        @include regular-width {
            float: right;
            margin-left: 1.5em;
            & + p {
                text-align: left;
            }
        }
        @include smallish-width {
            svg {
                max-width: 100%;
                display: block;
                margin: 0 auto;
            }
        }
    }
}

li {
//    list-style-position: inside;
    margin-left: 2em;
}
q {
    &::before, &::after { content: ""; }
}
.footnotes {
    color: #999;
    font-size: 0.8em;
    a { color: #333; }
    a:visited { color: #555; }
}
hr.footnotesep {
    margin: 3em 0 1em;
    width: 3em;
    border: none;
    height: 1px;
    background-color: #ddd;
}
[id^="fnref:"].ref-highlight, // footnote links
[id^="fn:"].ref-highlight [href^="#fnref:"] // footnote backlinks
{
    background:yellow;
    padding-left:0.5em;
    padding-right:0.5em;
}
#disqus_thread {
    margin: 0 0 2em;
}
footer.share {
    margin: 2em 0 2em;
    padding: 0;
    .retweet-link {
        margin-right: 1em;
        font-size: 13px;
        line-height: 20px;
        color: #AAB8C2;
        &:hover { color: #19CF86; }
        font-family: "Helvetica Neue", Arial, sans-serif;
        text-decoration: none;
        path { fill: #AAB8C2; };
        &:hover path { fill: #19CF86; };
    }
}
.twitter-follow-button, .retweet-link svg {
    vertical-align: bottom;
}

svg.playground-plot {
    -webkit-tap-highlight-color:transparent;
//    width: 100%;
    font-size: 9pt;
    .axis {
        line, path {
            fill: none;
            stroke: black;
//          stroke-width: 1px;
            shape-rendering: crispEdges;
        }
        text {
            fill: black;
        }
    }
    line, path {
        vector-effect: non-scaling-stroke;
    }
    .error-bar {
        shape-rendering: crispEdges;
        stroke: #ccc;
    }
    .point {
        fill:#57a9f5;
    }
    .point.closest {
        fill: #fe8132;
    }
    line.closest {
        shape-rendering: crispEdges;
        stroke: #ccc;
    }
    .label {
        text-anchor:middle;
    }
}